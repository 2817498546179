import { Injectable } from '@angular/core';
import {Reservation} from './reservation';
 
@Injectable()
export class NewReservationService {
 
    private _newReservation:Reservation = new Reservation();
    private _baySlotsArr = [];
    private _resArr = null;
    private _walkInLocked = false;
 
    createNewReservation(newRes: Reservation) {
        this._newReservation = newRes;
    }

    getReservation(): Reservation {
        return this._newReservation;
    }
 
    resetNewReservation() {
        this._newReservation = new Reservation();
    }

    setBaySlotsArr(bayArrays) {
        this._baySlotsArr = bayArrays;
    }

    getBaySlotsArr() {
        return this._baySlotsArr;
    }

    setResArr(resArr) {
        this._resArr = resArr;
    }

    getResArr() {
        return this._resArr;
    }

    lockWalkIn() {
        this._walkInLocked = true;
    }

    unlockWalkIn() {
        this._walkInLocked = false;
    }
}