import { Injectable } from '@angular/core';
import { Reservation } from '../shared/reservation';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  reservationListRef: AngularFireList<any>;
  closedBaysListRef: AngularFireList<any>;
  reservationRef: AngularFireObject<any>;
  walkinStartActivityRef: AngularFireObject<any>;
  closedBaysRef: AngularFireObject<any>;
  date = '';

  constructor(private db: AngularFireDatabase) {
    this.walkinStartActivityRef = this.db.object('/start-activity');
    this.closedBaysListRef = this.db.list('/closed-bays');
  }

  getWalkinStartActivity() {
    return this.walkinStartActivityRef;
  }

  // Get closed bays list
  getClosedBaysList() {
    return this.closedBaysListRef;
  }

  addClosedBay(key, date) {
    return this.closedBaysListRef.push({resKey: key, date: date});
  }

  removeClosedBay(id) {
    this.closedBaysRef = this.db.object('/closed-bays/' + id);
    this.closedBaysRef.remove();
  }

  updateClosedBay(id, date) {
    this.closedBaysRef = this.db.object('/closed-bays/' + id);
    this.closedBaysRef.update({date: date});
  }

  setWalkinStartActivityDate(date) {
    return this.walkinStartActivityRef.update({date: date, start: 0});
  }

  setWalkinLocked(lockValue) {
    if(lockValue) {
      return this.walkinStartActivityRef.update({locked: lockValue});
    } else {
      setTimeout(() => {
        return this.walkinStartActivityRef.update({locked: lockValue});
      }, 10000);
    }
  }

  setWalkinStartActivityNum(num) {
    return this.walkinStartActivityRef.update({start: num});
  }

  setWalkinShotgun(shotgunOn) {
    return this.walkinStartActivityRef.update({shotgun: shotgunOn});
  }

  setWalkinShotgunFirstTime(startTime) {
    return this.walkinStartActivityRef.update({shotgunStart: startTime});
  }

  loadReservations(date) {
    this.reservationListRef = this.db.list('/reservation', ref => ref.orderByChild('date').equalTo(date));
  }
  // Create
  createReservation(reservation: Reservation) {
    return this.reservationListRef.push({
      party: reservation.party,
      time: reservation.time,
      length: reservation.length,
      mainGuest: reservation.mainGuest,
      size: reservation.size,
      bay: reservation.bay,
      type: reservation.type,
      server: reservation.server,
      color: reservation.color,
      date: reservation.date,
      slotNum: reservation.slotNum,
      partyKey: reservation.partyKey
    });
  }
  // Get Single by id
  getReservation(id: string) {
    this.reservationRef = this.db.object('/reservation/' + id);
    return this.reservationRef;
  }

  // Get List
  getReservationList() {
    return this.reservationListRef;
  }
  // Update
  updateReservation(id, reservation: Reservation) {
    if(!this.reservationRef) {
      this.reservationRef = this.db.object('/reservation/' + id);
    }
    console.log('reservation date: ', reservation.date);
    return this.reservationRef.update({
      party: reservation.party,
      time: reservation.time,
      length: reservation.length,
      mainGuest: reservation.mainGuest,
      size: reservation.size,
      bay: reservation.bay,
      type: reservation.type,
      server: reservation.server,
      color: reservation.color,
      date: reservation.date,
      slotNum: reservation.slotNum
    });
  }
  // Delete
  deleteReservation(id: string) {
    this.reservationRef = this.db.object('/reservation/' + id);
    this.reservationRef.remove();
  }
}