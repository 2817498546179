export class Party {
    $key: string;
    name: string;
    email: string;
    mobile: number;
    mainGuest: string;
    size: number;
    status: string;
    activities: Array<{}>;
    server: string;
    date: string;
    color: string;
    serverId: string;
}