import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  endpoint = 'https://worldclockapi.com/api/json/cst/now';
  newTimeEndpoint = 'https://new.icompetex.com/cors.php?method=GET&cors='+'https://timeapi.io/api/Time/current/zone&timeZone=US/Central';
  icxTimeEndpoint = 'https://new.icompetex.com/cors.php?method=GET&cors='+'https://new.icompetex.com/date.php';

  httpOptions = {
    headers: new HttpHeaders({ 'accept': 'application/json' })
  };

  constructor(private httpClient: HttpClient) { }

  getTime(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.newTimeEndpoint)
      .pipe(
        tap(time => console.log('time retrieved!', time)),
        catchError(this.handleError<any[]>('Get time', []))
      );
  }

  getICXTime(): Observable<any> {
    return this.httpClient.get<any>(this.icxTimeEndpoint);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  } 
}
