import { Injectable } from '@angular/core';
import { Reservation } from './reservation';
import { Party } from './party';
 
@Injectable()
export class NewWalkInService {
 
    private _newReservations:Array<Reservation> = [];
    private _newParty = new Party();
    
 
    createNewWalkIn(newParty: Party) {
        this._newParty = newParty;
    }

    getWalkInParty(): Party {
        return this._newParty;
    }

    getReservations(): Array<Reservation> {
        return this._newReservations;
    }

    addReservation(res: Reservation){
        return this._newReservations.push(res);
    }
 
    resetNewWalkIn() {
        this._newParty = new Party();
        this._newReservations = [];
    }

}