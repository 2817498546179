import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'make-party',
    loadChildren: () => import('./make-party/make-party.module').then( m => m.MakePartyPageModule)
  },
  {
    path: 'make-walkin',
    loadChildren: () => import('./walk-in/make-walkin.module').then( m => m.MakeWalkinPageModule)
  },
  {
    path: 'view-reservations/:id',
    loadChildren: () => import('./view-reservations/view-reservations.module').then( m => m.ViewReservationsPageModule)
  },
  {
    path: 'edit-party/:id',
    loadChildren: () => import('./edit-party/edit-party.module').then( m => m.EditPartyPageModule)
  },
  {
    path: 'list-parties',
    loadChildren: () => import('./list-parties/list-parties.module').then( m => m.ListPartiesPageModule)
  },
  {
    path: 'make-reservation',
    loadChildren: () => import('./make-reservation/make-reservation.module').then( m => m.MakeReservationPageModule)
  },
  {
    path: 'edit-reservation',
    loadChildren: () => import('./edit-reservation/edit-reservation.module').then( m => m.EditReservationPageModule)
  },
  {
    path: 'edit-server/:id',
    loadChildren: () => import('./edit-server/edit-server.module').then( m => m.EditServerPageModule)
  },
  {
    path: 'make-server',
    loadChildren: () => import('./make-server/make-server.module').then( m => m.MakeServerPageModule)
  },
  {
    path: 'list-servers',
    loadChildren: () => import('./list-servers/list-servers.module').then( m => m.ListServersPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
