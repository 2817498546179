// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebaseConfig: {
  //   apiKey: "AIzaSyATX3z3Tq9_DMlFFBbxH5NXNyBtPJ6YO50",
  //   authDomain: "icx-waitlist-test.firebaseapp.com",
  //   databaseURL: "https://icx-waitlist-test-default-rtdb.firebaseio.com",
  //   projectId: "icx-waitlist-test",
  //   storageBucket: "icx-waitlist-test.appspot.com",
  //   messagingSenderId: "456959809463",
  //   appId: "1:456959809463:web:85eb10947b60eba127fa32",
  //   measurementId: "G-T3FX39RW8V"
  // }
  firebaseConfig: {
    apiKey: "AIzaSyAp9ds6JZImS-TfwgfXMTD0NYPTqvpfzV8",
    authDomain: "icxwaitlistreserve.firebaseapp.com",
    projectId: "icxwaitlistreserve",
    storageBucket: "icxwaitlistreserve.appspot.com",
    messagingSenderId: "391040624535",
    appId: "1:391040624535:web:a2ec7dd68ae8d91797c6d2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
