export class Reservation {
    $key: string;
    partyKey: string;
    party: string;
    time: string
    length: string;
    mainGuest: string;
    size: number;
    bay: string;
    type: string;
    activity: string;
    server: string;
    serverKey: string;
    color: string;
    date: string;
    slotNum: number;
}

export interface INewReservation {
    type: string;
    bay: number;
    date: string;
    time: string;
}